import CLink from '@/CLink';
import { PlainBtn, SecondaryBtn } from '@/buttons';
import { ReactComponent as AppointmentIcon } from '@a/icons/appointment.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import '@reach/dialog/styles.css';
import useLocationData from '@s/hooks/useLocationData';
import useMountTransition from '@s/hooks/useMountTransition';
import useRouteChange from '@s/hooks/useRouteChange';
import useScrollBlock from '@s/hooks/useScrollBlock';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment, useEffect, useState } from 'react';
import { navBtnStyles, navLinkStyles } from './NavLinkStyles';
import { NavLinksTypes, navLinks } from './NavLinks';
import { theme } from './styles/GlobalStyles';
import { flexCenter, flexSpace, px, scrollBar } from './styles/classes';

const IconBtn = styled(PlainBtn)<{ mainNavBtn?: boolean; dark?: boolean }>`
    > span {
        margin-left: 24px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.green2};
        transition: color 0.3s ease-in-out;
    }

    ${({ mainNavBtn, dark }) =>
        mainNavBtn &&
        css`
            ${flexCenter};
            flex-direction: column;
            margin-left: 32px;

            > span {
                margin-top: 8px;
                margin-left: 0 !important;
                color: ${dark ? theme.colors.gray3 : theme.colors.gray1};
                font-weight: 400;
                text-transform: none;
            }

            path {
                fill: ${dark ? theme.colors.gray3 : theme.colors.gray1};
            }
        `};

    path {
        transition: fill 0.3s ease-in-out;
    }

    :focus-visible {
        > span {
            color: ${({ theme }) => theme.colors.green1};
        }

        path {
            fill: ${({ theme }) => theme.colors.green1};
        }
    }

    :hover {
        > span {
            color: ${({ theme }) => theme.colors.green1};
        }

        path {
            fill: ${({ theme }) => theme.colors.green1};
        }
    }

    @media (min-width: 600px) {
        > span {
            margin-left: 44px;
        }
    }
`;

type SliderProps = {
    $hasTransitionedIn: boolean;
    $showDialog: boolean;
};

const NavSlider = styled(DialogContent, {
    shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<SliderProps>`
    &[data-reach-dialog-content] {
        ${px};
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        padding-top: 16px;
        padding-bottom: 16px;
        height: 100%;
        width: 100%;
        overflow: hidden;
        opacity: ${({ $showDialog, $hasTransitionedIn }) =>
            $showDialog && $hasTransitionedIn ? 1 : 0};
        transition: opacity 0.3s ease-in-out;
        z-index: 100;
        position: relative;

        // reset
        margin: 0;
    }
`;

const Bar = styled.div`
    ${flexSpace};
    padding-top: 5px;

    > ${IconBtn}:last-of-type {
        margin-left: auto;
    }

    @media (min-width: 1280px) {
        padding-top: 40px;
    }

    @media (pointer: fine) {
        padding-right: 20px;
    }
`;

const BackBtn = styled(IconBtn)`
    ${flexCenter};
    line-height: 150%;
    padding-bottom: 0 !important;
    border: 0 !important;
`;

const MainLinks = styled.nav<{ big: boolean }>`
    ${scrollBar};
    display: grid;
    overflow-y: auto;
    max-height: 85%;
    justify-content: center;
    place-self: center;
    gap: 32px;
    width: 100%;
    max-width: 305px;

    ${({ big }) =>
        big &&
        css`
            margin-top: 32px;

            @media (min-width: 768px) {
                max-width: 824px;
                grid-template-rows: repeat(11, auto);
                grid-auto-flow: column;
                justify-content: space-between;
                padding-right: 10px;
                gap: 32px;
            }
        `};
`;

const AppointmentBtn = styled(SecondaryBtn)`
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.gray3};
    color: ${({ theme }) => theme.colors.gray3};

    > svg {
        margin-right: 18px;

        path {
            fill: ${({ theme }) => theme.colors.gray3};
        }
    }

    @media (min-height: 700px) {
        margin-top: 40px;
    }
`;

const bgStyles = css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #f6f9fc;
        opacity: 0.8;
        z-index: 1;
    }

    @media (min-width: 1280px) {
        img {
            object-position: top center;
        }
    }
`;

type NavMenuProps = {
    text?: string;
    links?: NavLinksTypes;
    dialogClosed: boolean;
    closeAllDialogs: () => void;
    scrolled?: boolean;
};
export const NavMenu = ({
    text,
    links = navLinks,
    dialogClosed,
    closeAllDialogs,
    scrolled,
}: NavMenuProps) => {
    const [showDialog, setShowDialog] = useState(false);
    const toggleDialog = () => setShowDialog(show => !show);

    const hasTransitionedIn = useMountTransition(showDialog, 300);
    useScrollBlock(showDialog || hasTransitionedIn);

    useRouteChange(setShowDialog);

    const { category, subCategory, title } = useLocationData();

    useEffect(() => {
        setShowDialog(false);
    }, [dialogClosed]);

    return (
        <Fragment>
            {text ? (
                <PlainBtn
                    css={navBtnStyles}
                    onClick={toggleDialog}
                    className={
                        text.replaceAll(' / ', ' ') === subCategory ||
                        text.replaceAll(' / ', ' ') === category
                            ? 'current-nav-category'
                            : ''
                    }
                >
                    {text}
                </PlainBtn>
            ) : (
                <IconBtn
                    onClick={toggleDialog}
                    aria-label={`${showDialog ? 'close' : 'open'} nav menu`}
                    mainNavBtn
                    dark={scrolled || !!title}
                >
                    <svg
                        width="36"
                        height="24"
                        viewBox="0 0 36 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M0 24H36V20H0V24ZM0 14H36V10H0V14ZM0 0V4H36V0H0Z" fill="#161717" />
                    </svg>
                    <span>Menu</span>
                </IconBtn>
            )}
            {(showDialog || hasTransitionedIn) && (
                <DialogOverlay
                    onDismiss={toggleDialog}
                    dangerouslyBypassScrollLock
                    style={{ background: 'none', zIndex: 100 }}
                >
                    <NavSlider
                        aria-label={`${showDialog ? 'close' : 'open'} navigation menu`}
                        $showDialog={showDialog}
                        $hasTransitionedIn={hasTransitionedIn}
                    >
                        <Bar>
                            {text && (
                                <BackBtn onClick={toggleDialog} css={navLinkStyles}>
                                    <svg
                                        width="40"
                                        height="28"
                                        viewBox="0 0 40 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M14 28L16.82 25.18L7.66 16H40V12H7.66L16.84 2.82L14 0L0 14L14 28Z"
                                            fill="#6D8945"
                                        />
                                    </svg>

                                    <span>{text}</span>
                                </BackBtn>
                            )}
                            <IconBtn onClick={closeAllDialogs} style={{ zIndex: 10000 }}>
                                <svg
                                    width="28"
                                    height="28"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M28 2.82L25.18 0L14 11.18L2.82 0L0 2.82L11.18 14L0 25.18L2.82 28L14 16.82L25.18 28L28 25.18L16.82 14L28 2.82Z"
                                        fill="#6D8945"
                                    />
                                </svg>
                            </IconBtn>
                        </Bar>

                        <MainLinks big={links.length > 10}>
                            {links.map((data, i) =>
                                data.links ? (
                                    <NavMenu
                                        text={data.text}
                                        links={data.links}
                                        key={i}
                                        dialogClosed={dialogClosed}
                                        closeAllDialogs={closeAllDialogs}
                                    />
                                ) : data.text === 'appointment' ? (
                                    <AppointmentBtn as={CLink} to={data.link} key={i}>
                                        <AppointmentIcon /> Book an Appointment
                                    </AppointmentBtn>
                                ) : (
                                    <CLink to={data.link} css={navLinkStyles} key={i}>
                                        {data.text}
                                    </CLink>
                                )
                            )}
                        </MainLinks>

                        <StaticImage
                            src="../../../assets/images/homepage/hero.jpg"
                            alt=" yeliana nikolskaya "
                            loading="eager"
                            css={bgStyles}
                            quality={70}
                        />
                    </NavSlider>
                </DialogOverlay>
            )}
        </Fragment>
    );
};
